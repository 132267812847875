.review-slider {
  background-color: #f1f1f1;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
}

.review-slider h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.review {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the total width */
  margin: 0 auto; /* Centers the review card */
}

.review p {
  font-size: 1.2em;
  color: #7f8c8d;
  margin-bottom: 10px;
}

.review h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #3498db;
}

.rating .star {
  color: #f39c12;
  font-size: 1.5em;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .review-slider h2 {
    font-size: 2em;
  }
  
  .review p {
    font-size: 1.1em;
  }
  
  .review h4 {
    font-size: 1.4em;
  }
}

@media (max-width: 768px) {
  .review-slider h2 {
    font-size: 1.8em;
  }
  
  .review p {
    font-size: 1em;
  }
  
  .review h4 {
    font-size: 1.3em;
  }
  
  .rating .star {
    font-size: 1.4em;
  }
}

@media (max-width: 480px) {
  .review-slider {
    padding: 30px 15px; /* Slightly reduced padding for very small screens */
  }
  
  .review-slider h2 {
    font-size: 1.5em;
  }
  
  .review p {
    font-size: 0.9em;
  }
  
  .review h4 {
    font-size: 1.2em;
  }
  
  .rating .star {
    font-size: 1.3em;
  }
}

/* Add sliding effect */
.slick-slide {
  transition: transform 0.5s ease-in-out;
}

/* Add fade effect for slides */
.slick-slider .slick-slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slick-slider .slick-active {
  opacity: 1;
}
