.why-choose-us {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
}

.why-choose-us h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.feature {
  flex: 1 1 300px; /* Flex-grow, flex-shrink, and flex-basis */
  max-width: 300px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #3498db;
}

.feature p {
  font-size: 1em;
  color: #7f8c8d;
}

.feature:hover {
  transform: translateY(-5px); /* Slight hover effect for better interaction */
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .why-choose-us h2 {
    font-size: 2em;
  }
  .feature {
    max-width: 45%; /* Two features per row on medium screens */
  }
}

@media (max-width: 768px) {
  .why-choose-us h2 {
    font-size: 1.8em;
  }
  .feature {
    max-width: 100%; /* Full-width features on small screens */
  }
}

@media (max-width: 480px) {
  .why-choose-us {
    padding: 30px 15px; /* Slightly reduced padding for very small screens */
  }
  .why-choose-us h2 {
    font-size: 1.5em;
  }
}
