/* Cart item styling */
/* 26  Navbar styling */
/* 105 about */
/* 222  cart page */
/* 362 product detail page */
/* 677 product list */

/* Cart item styling */
.cart {
    padding: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .cart-item-image {
    width: 50px; /* Adjust image size */
    height: 50px;
    margin-right: 10px; /* Space between image and other elements */
  }
  
  .cart-item button {
    margin-left: 10px; /* Space between buttons */
  }
  
  /* Add this CSS to your stylesheet */
/* Styling for the search bar */
.search-bar {
  text-align: center;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  max-width: 600px;
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: box-shadow 0.3s, border-color 0.3s;
}

/* Focus state for the input */
.search-bar input:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-color: #888;
}



/* Navbar styling */
.navbar {
    background: #4C702B;/*linear-gradient(to right, #4C702B, rgb(232, 236, 207)); #e8ae2a;#00C1C9;*/
    padding: 25px;
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between logo and links */
    align-items: center; /* Align items vertically */
    height: 20px;
  }
  
  .navbar-logo img {
    height: 50px; /* Adjust logo height */
  }
  .h2{
    color: #fefefe;
  }
  .navbar-link {
    margin-right: 10px; /* Space between links */
    text-decoration: none;
    color: #fefefe;
    font-size: 14px; 
  }
  .navbar-name{
    /* margin-left: 1px; Space between links */
    text-decoration: none;
    color: #fefefe;
    font-size: 24px; 
  }

  .call-button {
    position: fixed; /* Fixed positioning to float on the screen */
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    background-color: #28a745; /* Background color */
    color: white; /* Icon color */
    border: none; /* No border */
    border-radius: 50%; /* Circular shape */
    padding: 15px; /* Padding around the icon */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    z-index: 1000; /* Ensure it appears above other elements */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .call-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .call-button:active {
    background-color: #1e7e34; /* Even darker green on click */
  }

  .call-button,
.whatsapp-button {
  position: fixed; /* Fixed positioning to float on the screen */
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  background-color: #28a745; /* Background color */
  color: white; /* Icon color */
  border: none; /* No border */
  border-radius: 50%; /* Circular shape */
  padding: 15px; /* Padding around the icon */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  z-index: 1000; /* Ensure it appears above other elements */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.call-button:hover,
.whatsapp-button:hover {
  background-color: #090a09; /* Darker green on hover */
}

.call-button:active,
.whatsapp-button:active {
  background-color: #1e7e34; /* Even darker green on click */
}

.whatsapp-button {
  bottom: 80px; /* Adjust the position to avoid overlap with call button */
  background-color: #25d366; /* WhatsApp green color */
}

.whatsapp-button:hover {
  background-color: #1ebe4b; /* Darker green on hover */
}

.whatsapp-button:active {
  background-color: #1a9f40; /* Even darker green on click */
}
  
  @media (max-width: 600px) {
    .navbar {
      background: #4C702B;/*linear-gradient(to right, #4C702B, rgb(232, 236, 207)); #e8ae2a;#00C1C9;*/
      padding: 15px;
      display: flex; /* Use flexbox for alignment */
      justify-content: space-between; /* Space between logo and links */
      align-items: center; /* Align items vertically */
      height: 20px;
    }
    .navbar-name{
      margin-left: 1px; /* Space between links */
      text-decoration: wavy;
      color: #fefefe;
      font-size: 14px; 
    }
  }
  
  .navbar-link:hover {
    text-decoration: none; /* Underline on hover */
  }
  


  .offer-banner {
    width: 100%;
    height: 15vh; /* Default height */
    background-color:#e9ffab; /* Light background for better appearance */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better appearance */
    position: relative;
    overflow: hidden;
  }
  
  .offer-banner h2 {
    margin: 0; /* Remove default margin */
    padding: 0 5vw; /* Add some padding for better spacing */
    font-size: 5vw; /* Adjust font size relative to viewport width */
    color: #4C702B; /* Neon text color */
    text-align: center;
    /* text-shadow: 0 0 5px rgba(76, 112, 43, 0.8), 0 0 10px rgba(76, 112, 43, 0.6), 0 0 20px rgba(76, 112, 43, 0.4), 0 0 30px rgba(76, 112, 43, 0.3), 0 0 40px rgba(76, 112, 43, 0.2), 0 0 50px rgba(76, 112, 43, 0.1); */
    animation: neon-text-flicker 1.5s infinite alternate;
  }
  
  /* Media query for large screens */
  @media (min-width: 1200px) {
    .offer-banner {
      height: 10vh; /* Adjust height for larger screens */
    }
  
    .offer-banner h2 {
      font-size: 3vw; /* Adjust font size for larger screens */
    }
  }
  
  /* Media query for medium screens */
  @media (max-width: 1199px) and (min-width: 768px) {
    .offer-banner {
      height: 12vh; /* Adjust height for medium screens */
    }
  
    .offer-banner h2 {
      font-size: 4vw; /* Adjust font size for medium screens */
    }
  }
  
  
  
/* ---------------------------------------------------------------------------------------about  */

/* Styles for the About page */

.about {
  display: flex;
    padding: 20px;
    text-align: center;
  }
  .aboutlogo{
    max-width: 10%;
    height: 80px;

  }
  .about h1 {
    color: #333;
  }
  
  .about p {
    color: #666;
    line-height: 1.6;
  }
  .mapg{
    margin-right: 1px;
    float: right;
    text-align: left;

  }
  .adress_g{
    /* margin-left: 1px; */
    /* float: left; */
    text-align: left;
    /* align-items: end; */
  }
  
  /* new div alling  105 about*/

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    padding: 20px;
  }
  
  .top-div {
    width: auto;
    height: 40%;
    background-color: lightblue;
    padding: 20px;
    text-align: center;
    font-size: 18px; 
  }
  
  .bottom-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }
  
  .side-div {
    width: 48%;
    background-color: lightgreen;
    padding: 20px;
    text-align: left;
  }
  .addrestext{
    padding: 5px;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    
    margin-bottom: 1em; /* Optional: Add space between paragraphs */

  }
  .word{
    padding: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;

  }
  .aboutmaintext{
    padding: 5px;
    text-align: center;
    font-size: 18px;
    text-indent: 2em; /* Indent the first line by 2 em units */
    margin-bottom: 1em; /* Optional: Add space between paragraphs */

  }
  .aboutsecondtext{
    padding: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    text-indent: 2em; /* Indent the first line by 2 em units */
    margin-bottom: 1em; /* Optional: Add space between paragraphs */

  }
  .questiontext{
    text-align: justify;
    
  }

  @media (max-width: 600px) {
    .top-div {
      width: auto;
      height: 40%;
      background-color: lightblue;
      padding: 20px;
      text-align: center;
      font-size: 18px; 
    }
    .bottom-container {
      flex-direction: column;
      align-items: center;
    }
  
    .side-div {
      width: 48%;
      margin-bottom: 10px; /* Add some space between the two side divs */
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      width: auto; /* Full viewport height */
    }
    .word{
      padding: 5px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
  
    }
    .aboutlogo{
      max-width: auto;
      height: auto;
  
    }
    .aboutsecondtext{
      padding: 5px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      /* text-indent: 2em; Indent the first line by 2 em units */
      /* margin-bottom: 1em; Optional: Add space between paragraphs */
    }
  }
/* ---------------------------------------------------------------------------------------about  */
/* Styles for the cart page */
.cart {
  text-align: center;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .cart-items {
    display: flex;
    flex-direction: column; /* Column layout by default */
    gap: 20px; /* Space between cart items */
  }
  
  .cart-item {
    display: flex;
    align-items: center; /* Align items horizontally */
    justify-content: space-between; /* Space between sections */
    border: 1px solid #ddd;
    border-radius: 10px; /* Rounded corners */
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  .cart-item-image-wrapper {
    flex-shrink: 0; /* Prevent image from shrinking */
  }
  
  .cart-item-image {
    width: 160px; /* Image size */
    height: 160px;
    border-radius: 5px; /* Rounded corners */
  }
  
  .cart-item-details {
    flex-grow: 1; /* Allow details to expand */
  }
  
  .cart-item-actions {
    display: flex;
    gap: 10px; /* Space between action buttons */
  }
  
  .cart-item button {
    justify-content: center;
    padding: 8px;
    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cart-item button:hover {
    background-color: #0056b3; /* Darker on hover */
  }
  
  /* Media queries for device responsiveness */
  @media (max-width: 600px) {
    .cart {
      padding: 20px;
      align-items: center;
      justify-content: center;
    } /* Mobile devices */
    .cart-item {
      flex-direction: column; /* Change to vertical layout */
      align-items: flex-start; /* Align items to the left */
    }
  
    .cart-item-image-wrapper {
      margin-bottom: 10px; /* Add space below image */
    }
  }
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .cart {
      padding: 20px;
      align-items: center;
      justify-content: center;
    } /* Tablets */
    .cart-item {
      flex-direction: row; /* Horizontal layout */
      justify-content: space-between; /* Ensure proper alignment */
    }
  }
  


/* Grid layout for 296 product list */
.product-list {
  padding: 60px;
  background: #fbfbfb;/*linear-gradient(to right, rgb(254, 248, 187),#fefefe, rgb(254, 248, 187));  #efc155;#EDF9FC;*/
}
.h1cl{
  text-align: center;
  font-size: 18px;
}
.product-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between grid items */
}

.product-card {
  
  background:linear-gradient(to right, #fdfff6,#fefefe, rgb(241, 241, 227));/* #DDD07E ;*/
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  text-align: center; /* Center-align text */
  transition: box-shadow 0.2s;
}

.product-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Hover effect */
}

.product-card-image {
  width: 100%; /* Ensure image fills the card */
  height: 250px;
  border-radius: 10px; /* Rounded corners */
}

.product-card h3 {
  margin-top: 10px; /* Space between image and text */
}

.product-card p {
  color: #666; /* Adjust text color */
}

@media (max-width: 600px) {
  .h1cl{
    text-align: center;
    font-size: 14px;
  }
  .product-card {
  
    background:linear-gradient(to right, #fdfff6,#fefefe, rgb(241, 241, 227));/* #DDD07E ;*/
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    text-align: center; /* Center-align text */
    transition: box-shadow 0.2s;
    height: auto;
  }
  .product-card-image {
    width: 100%; /* Ensure image fills the card */
    height: 250px;
    border-radius: 10px; /* Rounded corners */
  }
}


/* Flexbox layout for the 362 product detail page */
.product-detail {
  display: flex;
  gap: 20px;
  padding: 20px;
  align-items: flex-start; /* Align items at the top */
  justify-items: center;
}

.product-image-large-wrapper {
  display: flex; /* Use flexbox for image wrapper */
  justify-content: center; /* Center the image wrapper */
  flex: 2;
  max-width: 900px; /* Adjust max-width to match image size */
  padding: 10px;
  border-radius: 10px;
}

.product-image-large {
  justify-content: center;
  width: 100%; /* Allow the image to take full width of the wrapper */
  max-width: 900px; /* Set a max width to prevent overflow */
  height: 400px; /* Maintain aspect ratio */
  border-radius: 30px;
  transition: transform 0.3s;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.product-image-large-wrapper:hover .product-image-large {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.largeimagemiddlecontainer{
  text-align: center; /* Center text and inline elements */
  padding: 20px; /* Optional padding */

}

.product-info-section {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center vertically */
}

.product-thumbnails {
  

  display: flex;
    flex-direction: row; /* Vertical layout for the gallery */
    gap: 10px; /* Space between thumbnails */
    align-items: center;
    justify-content: center;
}

.product-thumbnail {
  width: 70px; /* Thumbnail size */
    height: 80px; /* Maintain aspect ratio */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: transform 0.2s; /* Animation for hover */
    
}

.product-thumbnail:hover {
  transform: scale(1.2); /* Enlarge on hover */
}

.add-to-cart-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer; 
  /* Change cursor */
}

.add-to-cart-button:hover {
  background-color: #0056b3; /* Darker on hover */
}

.product-size-price {
  margin-left: 10px; /* Space between size selection and price */
  font-size: 1.1em;
}
.product-price{
  margin-left: 10px;
}
.centerbuttoncart{
  display: flex;
  flex-direction: column;
  align-items: center;

}
.largecontainer{
  display: flex; /* Use flexbox to center elements */
  justify-content: center; /* Center horizontally */
  padding: 0 30px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.p_discreption_mobile{
  display: none;
}
.p_discreption_desktop{
  display: block;
}
.product-selections{
  text-align: center;

}


.product-image-large:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .largeimagemiddlecontainer {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .product-image-large {
    width: 10%; /* Adjust image width for mobile view */
    max-width: 300px; /* Set a max width for smaller screens */
  }

  /* Optional: Adjust padding for mobile view */
  .largeimagemiddlecontainer {
    padding: 10px; /* Less padding on mobile */
  }
}
/* m */
@media (max-width: 767px) {
  .product-selections{
    text-align: center;
  
  }
  
  .largecontainer{/*d*/
    width: 100%; /* Ensure the image fills the wrapper */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s; /* Smooth transition */
  border: 1px solid #ddd; /* Light border around the image */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .product-detail {
    display: flex; /* Horizontal Flexbox layout */
    gap: 40px; /* Space between sections */
    padding: 20px; /* Padding for the overall section */
    align-items: auto;
  }
  .product-image-large {
    width: auto; /* Ensure the image fills the wrapper */
    align-items: center;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners */
    transition: transform 0.3s; /* Smooth transition */
    border: 1px solid #ddd; /* Light border around the image */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .product-image-large-wrapper:hover .product-image-large {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  .product-thumbnails {
    display: flex;
    flex-direction: row; /* Vertical layout for the gallery */
    gap: 10px; /* Space between thumbnails */
    align-items: center;
  }
  .product-thumbnail {
    width: 50px; /* Thumbnail size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: transform 0.2s; /* Animation for hover */
    align-self: center;
    /* padding: 20px */
  }

  .product-info-section {
    flex: 1; /* Smaller portion for product info */
    align-items: center;
     font-size: 1em;
     line-height:1.2em ;
  }
  .p_discreption_mobile{
    text-align: left;
   
    display: none;
  }
  .p_discreption_desktop{
    display: block;
  }
  
  
  
}
/* m */
@media (max-width: 600px) {
  .largecontainer{
    width: auto; /* Ensure the image fills the wrapper */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s; /* Smooth transition */
  border: 1px solid #ddd; /* Light border around the image */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .product-detail {
    width: auto;
    display: flex; /* Horizontal Flexbox layout */
    gap: 20px; /* Space between sections */
    padding: 20px; /* Padding for the overall section */
    justify-content: center;
  }
  .product-image-large {
    width: 500px; /* Ensure the image fills the wrapper */
    justify-content: center;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Rounded corners */
    transition: transform 0.3s; /* Smooth transition */
    border: 1px solid #ddd; /* Light border around the image */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  .product-image-large-wrapper:hover .product-image-large {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  .product-thumbnails {
    display: flex;
    flex-direction: row; /* Vertical layout for the gallery */
    gap: 10px; /* Space between thumbnails */
    align-items: center;
  }
  .product-thumbnail {
    width: 50px; /* Thumbnail size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    transition: transform 0.2s; /* Animation for hover */
    /* align-self: center; */
    /* padding: 20px */
  }

  .product-info-section {
    flex: 1; /* Smaller portion for product info */
    align-items: center;
  }
  .p_discreption_mobile{
    /* text-align: justify; */
    display: block;
  }
  .p_discreption_desktop{
    text-align: center;
    padding: 10px;
    display: none;
  }
  .product-selections{
    text-align: center;
  
  }
  
  
}

/* Flexbox layout for cart items */
.cart {
  padding: 20px;
   /* Padding for the cart container */
}

.cart-items {
  display: flex;
  flex-direction: column; /* Vertical layout for cart items */
  gap: 20px; /* Space between cart items */
}

.cart-item {
  display: flex; /* Flexbox layout */
  align-items: center; /* Align vertically */
  border-radius: 10px; /* Rounded corners */
  border: 1px solid #ddd; /* Border */
  padding: 10px; /* Padding for cart items */
  justify-content: space-between; /* Space between sections */
}

.cart-item-image-wrapper {
  flex-shrink: 0; /* Prevent shrinking */
}

.cart-item-image {
  width: 100%; /* Adjust image size */
  border-radius: 5px; /* Rounded corners */
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 50px; /* Allow details to grow */
}

.cart-item-actions {
  display: flex;
  gap: 10px; /* Space between action buttons */
}

.cart-item-actions button {
  background-color: #007bff; /* Button color */
  color: white;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer; /* Pointer cursor */
}

.cart-item-actions button:hover {
  background-color: #0056b3; /* Hover effect */
}
.add-to-cart-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer; /* Change cursor */
}




/* src/styles.css */

/* General styling for the 677 product list page */
.product-list {
  padding: 20px;
}

.product-list-grid {
  display: grid; /* Grid layout for the product grid */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between grid items */
}

/* Style for the slider images */
/* .slider-image {

  display: block;

  width:  100%;
  height: 390px;
  border-radius: 14px;
}

.image-slider {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.image-slider img {
  width: 100%;
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.next-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}
.prev-arrow{
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 768px) {
  .slider-image {
  border-radius: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:  300px;
    height: 400px;
  }
  .next-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  .prev-arrow{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .next-arrow:hover {
    background-color:  #4C702B;
  }
  .prev-arrow:hover {
    background-color:  #4C702B;
  }
}
 */

/* new sllider  */

/* Add this CSS to your styles.css or an appropriate CSS file */

.slider-container {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto; /* Center align */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.next-arrow {
  right: 10px; /* Adjust position as needed */
}

.prev-arrow {
  left: 10px; /* Adjust position as needed */
}

@media (max-width: 768px) {
  .arrow {
    width: 20px;
    height: 20px;
  }
}


/* General styling for the product list page */
.product-list {
  padding: 10px;
  margin-left : 60px;
  margin-right : 60px;
}

/* Grid layout for product cards */
.product-list-grid {
  display: grid; /* Use CSS Grid */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Flexible grid */
  gap: 20px; /* Space between grid items */
}
/* Media queries for responsive design */
@media (max-width: 600px) { /* For mobile devices */
  .product-list {
    /* padding: 30px; */
    margin-left : 10px;
  margin-right : 10px;
  }
  .product-list-grid {
    grid-template-columns: repeat(2, 1fr); /* Show 2 cards in a row */
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Styles for the order form */
.book-order-form-overlay {
  position: fixed; /* Fixed position for the overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center; /* Center-align the form */
  align-items: center; /* Center-align the form */
}

.book-order-form {
  background: white;
  padding: 20px;
  border-radius: 10px; /* Rounded corners */
  width: 400px; /* Fixed width */
}

.book-order-form form {
  display: flex;
  flex-direction: column; /* Vertical layout */
  gap: 15px; /* Space between elements */
}

.book-order-form form input,
.book-order-form form textarea {
  width: 100%; /* Ensure inputs fill the form */
}

.book-order-form form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.book-order-form form button[type="submit"] {
  background: #28a745; /* Green for submit button */
  color: white;
}

.book-order-form form button[type="button"] {
  background: #dc3545; /* Red for cancel button */
  color: white;
}
