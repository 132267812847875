/* src/UserInfoModal.css */

/* General styling for the modal */
.modal-overlay {
    position: fixed; /* Ensure it covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Center the modal */
    align-items: center; /* Vertically center */
  }
  
  .modal-content {
    background: white; /* White background for the modal */
    border-radius: 10px; /* Rounded corners */
    padding: 30px; /* Padding inside the modal */
    width: 300px; /* Width of the modal */
  }
  
  .modal-content h2 {
    font-size: 1.5em; /* Font size for the title */
    margin-bottom: 20px; /* Space below the title */
  }
  
  .modal-content input {
    display: block;
    width: 99%; /* Full width input fields */
    padding: 10px; /* Padding inside the input fields */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ddd; /* Light border */
    margin-bottom: 15px; /* Space between input fields */
  }
  
  .modal-content button {
    background-color: #007bff; /* Blue color for the button */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer; /* Change cursor on hover */
    transition: background-color 0.2s; /* Smooth transition */
  }
  
  .modal-content button:hover {
    background-color: #0056b3; /* Darker on hover */
  }
  