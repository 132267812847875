.media-slider {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
}

.media-slider h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.media-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-image, .media-video {
  width: 100%;
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
  object-fit: cover; /* Ensures the content covers the entire area without stretching */
}

.slick-prev, .slick-next {
  width: 24px;
  height: 24px;
  z-index: 1;
}

.slick-prev:before, .slick-next:before {
  content: '';
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .media-slider h2 {
    font-size: 2em;
  }
  .media-image, .media-video {
    max-height: 350px;
  }
}

@media (max-width: 768px) {
  .media-slider h2 {
    font-size: 1.8em;
  }
  .media-image, .media-video {
    max-height: 320px; /* Increased size for medium screens */
  }
}

@media (max-width: 480px) {
  .media-slider {
    padding: 30px 15px; /* Slightly reduced padding for very small screens */
  }
  .media-slider h2 {
    font-size: 1.8em; /* Increased size for small screens */
  }
  .media-image, .media-video {
    max-height: 320px; /* Increased size for small screens */
  }
}

/* Add sliding effect */
.slick-slide {
  transition: transform 0.5s ease-in-out;
}

/* Add fade effect for slides */
.slick-slider .slick-slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slick-slider .slick-active {
  opacity: 1;
}

